import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InfoModal from "./photos/components/infoModal";

function Home() {
  const [username, setUsername] = useState("");
  const [error, seterror] = useState(false);
  const navigate = useNavigate();

  const generateRandomString = (length) => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length)
      );
    }
    return result;
  };

  const handleStartClick = () => {
    if (username.trim() === "") {
      seterror(true);
      return;
    }
    const randomString = generateRandomString(6);
    localStorage.setItem("Name", username);
    localStorage.setItem("Id", randomString);
    navigate("/uploadPhotos");
  };

  return (
    <div className=" h-screen overflow-hidden w-screen relative flex-col gap-4 flex items-center justify-center text-white p-5 font-della">
      <div className="absolute top-16 left-4 right-4 text-center">
        <h1 className="text-3xl">Mandalena </h1>
        <h1 className="text-3xl">02.09.2023.</h1>
      </div>
      <h1 className="text-3xl">Dobrodošli</h1>
      <div className="w-full">
        <input
          type="text"
          placeholder="Unesite ime i prezime"
          value={username}
          className="p-2 w-full rounded-md text-elegance text-center"
          onChange={(e) => setUsername(e.target.value)}
        />

        {error && <p className="text-red-500 text-center">Ime je obavzno</p>}
      </div>

      <button
        className="w-full p-2  rounded-md border border-white"
        onClick={handleStartClick}
      >
        Start
      </button>
      <InfoModal />
    </div>
  );
}

export default Home;
