import React, { useState, useEffect } from "react";
import ImageViewer from "../viewPhotos/components/bigImage";
import ImageCard from "../viewPhotos/components/imageCard";
import { ArrowLeft, Download } from "lucide-react";

function ViewPhotosGuest() {
  const [imageNames, setImageNames] = useState([]);
  const [showViewer, setShowViewer] = useState(false);
  const [startImageIndex, setStartImageIndex] = useState(0);

  const handleImageClick = (index) => {
    console.log(index);
    setStartImageIndex(index);
    setShowViewer(true);
  };

 

  useEffect(() => {
    window.scrollTo(0,0)
    const id = localStorage.getItem("Id");
    const apiUrl = `https://mandalena02092023.personaliziranipokloni.com/getImagesGuest.php?id=${id}`;

    fetch(apiUrl)
      .then((response) => response.json())
      .then((data) => setImageNames(data.reverse()))
      .catch((error) => {
        console.error("Dogodila se greška:", error);
      });
  }, []);

  const imageBaseUrl =
    "https://mandalena02092023.personaliziranipokloni.com/uploads/";

  const imageUrls = imageNames.map((imageName) => imageBaseUrl + imageName);

  function downloadPhotos() {
    imageUrls.forEach((url, index) => {
        const link = document.createElement('a');
        link.href = url;
        link.target = '_blank';
        link.download = `Marija&Stipe25082023_${index + 1}.jpg`; 
        link.click(); 
    });
}

  return (
    <div className="bg-elegance relative p-4 font-della min-h-[95vh]">

      <a href="/uploadPhotos">
        <div className="text-elegance p-3 bg-white w-full flex  gap-4 absolute top-0 left-0">
          <ArrowLeft />
          <p>Povratak na dodavanje fotografija</p>
        </div>
      </a>

      <div onClick={downloadPhotos} className="text-elegance justify-center p-3 bg-white w-full flex  gap-4 absolute bottom-0 left-0">
          <Download />
          <p>Preuzmi fotografije</p>
        </div>

      <ImageViewer
        startImgIndex={startImageIndex}
        imageUrls={imageUrls}
        showImage={showViewer}
        onClose={() => setShowViewer(false)}
      />
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 py-12 ">
        {imageNames.map((imageName, index) => (
          <ImageCard
            key={index}
            name={imageName.split("-")[2]}
            time={imageName.split("-")[0]}
            onClick={() => handleImageClick(index)}
            src={`${imageBaseUrl}${imageName}`}
          />
        ))}
      </div>
      {imageNames.length === 0 && (
        <div className="h-full w-full bg-elegance flex items-center justify-center text-white">
          <h1 className="text-3xl text-center">
            Još uvijek nije objavljena nijedna fotografija!
          </h1>
        </div>
      )}
    </div>
  );
}

export default ViewPhotosGuest;
